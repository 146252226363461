/* eslint-disable react/prop-types */

/* Plugins */
import React from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { graphql, navigate } from 'gatsby';
import { handleCleanTitle, handleCustomizeName, handleCleanPath } from '../../utils/utils';

/* Components */
import {
  Container, Heading, Section, Figure, SEO, Article,
} from '../../components/components';

/* Styles */
import { StyledProductItemsWrapper } from '../../components/Semantic/Section/Section.styled';

/* Messages */
import * as messages from '../../messages/scianki/scianki.messages';

const Scianki = ({ data }) => (
  <>
    <SEO title="Ścianki" />
    <Container subPage>
      <Heading headingLevel="h1" title="Ścianki" centered />
      {messages.content.map(({ id, article }) => (
        <Section id={id} key={article}>
          <Article>
            <Markdown rehypePlugins={[rehypeRaw]} children={article} />
          </Article>
        </Section>
      ))}
      <Section>
        <StyledProductItemsWrapper>
          {data.scianki.edges.map(({ node }) => (
            <Figure
              key={node.id}
              productCategoryPage
              alt={handleCleanTitle(node.name)}
              title={handleCustomizeName(node.name)}
              image={node.childImageSharp.gatsbyImageData}
              onClick={() => navigate(handleCleanPath(node.name))}
            />
          ))}
        </StyledProductItemsWrapper>
      </Section>
    </Container>
  </>
);

export const pageQuery = graphql`
  query {
    scianki: allFile(filter: {relativeDirectory: {eq: "icons/scianki"}}) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default Scianki;
