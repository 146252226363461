export const content = [
  {
    id: 'skuteczna-reklama',
    article: `<p class="thin">Świadomość współczesnych przedsiębiorstw odnośnie nowoczesnych form marketingu stale się poszerza. Jest to korzystny trend, tym bardziej w czasach, w których konkurencja w wielu branżach jest bardzo duża. Ważne jest ciągłe docieranie do coraz to nowszych odbiorców, a co za tym idzie, do nowych klientów. Jedną z form innowacyjnego
      marketingu są standy reklamowe. Ścianki wystawiennicze składane charakteryzują się przede wszystkim swoją wytrzymałością oraz atrybutami wizualnymi. Dzięki swoim dużym rozmiarom przyciągają wzrok, a zamieszczane wewnątrz slogany i hasła łatwo są zapamiętywane przez losowych przechodniów. Popularnym rozwiązaniem jest ścianka tekstylna oraz reklamowa składana.</p>
      \n ## Skuteczna <span class="orange">reklama</span>
      \n W aspekcie marketingu bardzo ważna jest jego skuteczność i efektywność. Koszty realizowanych czynności muszą się zwracać. Docieranie do nowych klientów na początek generuje niewielkie straty, ale z czasem powraca do naszego budżetu ze zdwojoną siłą. W naszym sklepie internetowym stawiamy na jakość. Stand, ścianka reklamowe tekstylna,
      składana czy wystawiennicza dostępna w naszej ofercie to gwarancją niezawodności. Oprócz swojej wytrzymałości, jest także łatwe w montażu.
      \n Zamontowane standy reklamowe mogą pozostawać w niezmienionej lokacji przez wiele miesięcy. Dzięki swoim wyjątkowym cechom kolory się nie ścierają, a sam obiekt nie ulega uszkodzeniom. Zarówno stand, jak i ścianka reklamowa składana, wystawiennicza czy tekstylna wykazuje dużą odporność na zmieniające się warunki atmosferyczne.
      Skrajne upały czy ujemne temperatury, opady deszczu czy śniegu - żaden z powyższych czynników nie stanowi problemu dla naszych innowacyjnych rozwiązań marketingowych.
    `,
  },
  {
    id: 'rodzaje-ścianek',
    article: `## Rodzaje <span class="orange">ścianek</span>
    \n Oprócz podstawowego podziału na warianty wystawiennicze czy składane, nasi eksperci wytypowali również trzy podkategorie, do których należą ścianki:\n * banerowe,\n * tekstylne proste,\n * tekstylne łukowe,
    \n Każde z rozwiązań wystawienniczych lepiej sprawdzi się w innej lokalizacji. Jeżeli nie jesteś pewny, na którą z dostępnych opcji powinieneś się zdecydować, zachęcamy do kontaktu. Nasi eksperci zadadzą konkretne pytania i pomogą Ci wybrać optymalne standy dla Twoich indywidualnych preferencji oraz potrzeb.
    `,
  },
];
